import { qsa, qs } from "./utils"
import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

export const initGallery = () => {
  const galleries = qsa(".swiper")
  if (galleries.length) {
    galleries.forEach(renderGallery)
  }
}

const renderGallery = (gallery) => {
  const wrapper = gallery.parentNode
  const caption = qs("[data-caption]", wrapper)

  const initCaption = (e) => {
    const figcaptions = qsa("figcaption", e.slider)
    if (figcaptions.length) {
      caption.style.display = ""
      wrapper.classList.add("gallery--with-caption")
    }
  }

  const changeCaption = (e) => {
    const activeCaption = qs("figcaption", e.slides[e.activeIndex])

    if (activeCaption && caption) {
      const text = activeCaption.textContent
      caption.textContent = text
    } else {
      caption.textContent = ""
    }
  }

  new Swiper(gallery, {
    modules: [Navigation, Pagination],
    rewind: true,
    lazy: true,
    loadPrevNext: true,
    lazyPreloadPrevNext: 1,
    spaceBetween: 24,
    slidesPerView: 3,
    centeredSlides: true,
    grabCursor: true,
    loop: true,

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      slideChange: changeCaption,
      init: initCaption,
    },
  })
}
