import { qsa, qs } from "./utils"

export const initMapFilter = () => {
  const filterParents = qsa("[data-filter-parent]")
  if (filterParents.length) {
    const filterChildren = qsa("[data-areal]")
    const mapPlans = qsa("[data-plan]")
    const mapPlansHover = qsa("[data-plan-hover]")
    const mapRealestateHover = qsa("[data-realestate-hover]")
    const mapFuture = qsa("[data-future]")
    const overlay = qs("[data-map-overlay]")
    const closeButton = qs("[data-close-overlay]")
    let activTheme = null

    const togglePlanHover = (slug) => {
      for (let index = 0; index < mapPlansHover.length; index++) {
        const plan = mapPlansHover[index]
        if (plan.dataset.planHover === slug) {
          plan.classList.toggle("hideOpacity")
          plan.classList.toggle("addGlow")
          break
        }
      }
    }

    const toggleRealestateHover = (slug) => {
      for (const child of mapRealestateHover) {
        if (slug === child.dataset.realestateHover) {
          child.classList.remove("hideOpacity")
        } else {
          child.classList.toggle("hideOpacity")
        }
      }
    }

    const filterEventHover = (e) => {
      e.preventDefault()
      e.currentTarget.classList.toggle("hover")

      if (activTheme === "plan") {
        togglePlanHover(e.currentTarget.dataset.areal)
      } else if (activTheme === "realestate") {
        toggleRealestateHover(e.currentTarget.dataset.areal)
      } else if (activTheme === "future") {
        // CSS HOVER
      }
    }

    const mapEventHover = (e) => {
      e.preventDefault()
      if (activTheme === "plan") {
        const plan = e.currentTarget.dataset.plan
        qs(`[data-plan-hover=${plan}]`)?.classList.toggle("hideOpacity")
        qs(`[data-plan-hover=${plan}]`)?.classList.toggle("addGlow")
        qs(`[data-areal=${plan}]`)?.classList.toggle("hover")
      } else if (activTheme === "realestate") {
        const realestate = e.currentTarget.dataset.realestateHover
        qs(`[data-areal=${realestate}]`)?.classList.toggle("hover")
        for (const child of mapRealestateHover) {
          if (realestate === child.dataset.realestateHover) {
            child.classList.remove("hideOpacity")
          } else {
            child.classList.toggle("hideOpacity")
          }
        }
      } else if (activTheme === "future") {
        // CSS HOVER
      }
    }

    const clearOverlay = () => {
      const overlayContent = qs("[data-template-content]", overlay)
      if (overlayContent) overlayContent.remove()
    }

    const keyBoardEvent = (e) => {
      if (e.key === "Escape") {
        closeOverlay()
      }
    }

    const openOverlay = () => {
      overlay.style.display = ""
      document.body.addEventListener("keydown", keyBoardEvent)
    }

    const closeOverlay = () => {
      overlay.style.display = "none"
      clearOverlay()
      document.body.removeEventListener("keydown", keyBoardEvent)
      delete overlay.dataset.overlayTheme
    }

    const eventClick = (e) => {
      e.preventDefault()
      clearOverlay()
      const data = e.currentTarget.dataset

      const areal =
        data?.areal || data?.plan || data?.realestateHover || data?.future

      const theme =
        data?.parent ||
        (data?.plan ? "plan" : undefined) ||
        (data?.realestateHover ? "realestate" : undefined) ||
        (data?.future ? "future" : undefined)

      // disable realestate overlay
      if (areal && theme !== "realestate") {
        const overlayTemplate = qs(`[data-template="${areal}"]`, overlay)
        if (overlayTemplate) {
          const clone = document.importNode(overlayTemplate.content, true)
          overlay.firstElementChild.appendChild(clone)
        }

        overlay.dataset.overlayTheme = theme

        openOverlay()
      }
    }

    const toggleFilterChildren = (parent) => {
      for (const child of filterChildren) {
        if (parent === child.dataset.parent) {
          child.classList.remove("hide")
          child.addEventListener("click", eventClick, false)
        } else {
          child.classList.add("hide")
          child.removeEventListener("click", eventClick, false)
        }
      }
    }

    const filterParentEvent = (e) => {
      e.preventDefault()
      closeOverlay()
      for (const parent of filterParents) {
        parent.classList.remove("active")
      }
      e.currentTarget.classList.add("active")
      toggleFilterChildren(e.currentTarget.dataset.filterParent)
      const theme = e.currentTarget.dataset.filterParent
      activTheme = theme
      const all = [...mapPlans, ...mapFuture, ...mapRealestateHover]
      for (const item of all) {
        item.classList.add("hideOpacity")
        item.removeEventListener("mouseenter", mapEventHover, false)
        item.removeEventListener("mouseleave", mapEventHover, false)
        item.removeEventListener("click", eventClick, false)
      }
      if (theme === "plan") {
        for (const plan of mapPlans) {
          plan.classList.remove("hideOpacity")
          plan.addEventListener("mouseenter", mapEventHover, false)
          plan.addEventListener("mouseleave", mapEventHover, false)
          plan.addEventListener("click", eventClick, false)
        }
      } else if (theme === "realestate") {
        for (const realestate of mapRealestateHover) {
          realestate.classList.remove("hideOpacity")
          realestate.addEventListener("mouseenter", mapEventHover, false)
          realestate.addEventListener("mouseleave", mapEventHover, false)
          realestate.addEventListener("click", eventClick, false)
        }
      } else if (theme === "future") {
        for (const future of mapFuture) {
          future.classList.remove("hideOpacity")
          future.addEventListener("click", eventClick, false)
        }
      }
    }

    for (const areal of filterParents) {
      areal.addEventListener("click", filterParentEvent, false)
    }

    closeButton.addEventListener("click", closeOverlay)

    for (const filter of filterChildren) {
      filter.addEventListener("mouseenter", filterEventHover, false)
      filter.addEventListener("mouseleave", filterEventHover, false)
    }

    // "vorhaben" is active when start
    qs("[data-filter-parent='plan']").click()
  }
}
